$.ajaxSetup({
	headers: {
		'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	}
});

$('html').addClass('json');

var pingalaCMS = {

	load : function() {
		pingalaCMS.openWindow();
		pingalaCMS.printWindow();
		pingalaCMS.formStyle();
		if($('#slideimages').length > 0) { $(window).load(function(){$('#slideimages').fadeIn('slow');  if( $('#slideimages li').size()>1 ){setInterval( function() { pingalaWeb.fadeInOut("#slideimages")} , 6000);} }); }
		$( ".menu" ).click(function() { $( "#topnavi" ).toggleClass( "out" ); });
	},


	openWindow : function(){
		$('.openWindow').click(function() { window.open(this.href); return false});
	},

	printWindow : function(){
		$('.printWindow').click(function() { window.print(); });
	},

	formStyle : function(){

		if (document.createElement("input").placeholder == undefined) {
			$('.textform').each(function() { if(!$(this).val()) {$(this).val($(this).attr('placeholder'))} })
		}

		$('.textform').focus(function(){
			previous = $(this).attr('placeholder');  $(this).attr('placeholder', '');
			if( $(this).val() == previous ) {$(this).val('')}
		});

		$('.textform').blur(function() {
			$(this).attr('placeholder', previous);
		});


	}

};


var pingalaWeb = {

	liLinks : function() {

		$('#qlinks li').each(function() {
				$(this).addClass('hand');
				$(this).click(function() { window.location = $(this).find('a').attr('href'); });
		})
	},

	fadeInOut : function(slideUL){

		var curPhoto = $(slideUL+' li.active');
		var nextPhoto = curPhoto.next();
		if(nextPhoto.length == 0) { nextPhoto = $(slideUL+' li:first'); }
		curPhoto.removeClass('active').addClass('previous');
		nextPhoto.css({opacity:0.0}).addClass('active').animate({opacity: 1.0}, 1000, function() {
		curPhoto.removeClass('previous');
		});
	},

    lazyLoad : function() {

		$(window).scroll( function(){
	        $('.hide').each( function(i){
	            var bottom_of_object = $(this).position().top + $(this).outerHeight() - 300;
	            var bottom_of_window = $(window).scrollTop() + $(window).height();

	            if( bottom_of_window > bottom_of_object ){

	                $(this).animate({'opacity':'1'},800);
	                $(this).removeClass('hide');

	            }
	        });

    	});

	},

    expandpost : function() {

        $(".expand_post").click(function()  {
            $(this).toggleClass('collapse_post');
            $(this).html($(this).text() == 'Hide post' ? 'Show post' : 'Hide post');
            $(this).closest("article").toggleClass('expand');
        });

    },

    post_toggle : function() {

        $(".n1").click(function( event )   {
            event.preventDefault();
            goToByScroll(this.id);
            $("#post_to_timeline").toggleClass('blind');
        });

        function goToByScroll(id){
            $('html,body').animate({
                scrollTop: $("#post_to_timeline").offset().top-100},
                'slow');
        }

        $(".tn3").click(function()   {
            $("#post_to_timeline").toggleClass('blind');
        });

    },

    showform : function() {

        $("#menu").click(function() {
            $("#sidebar ul").toggleClass('menu_drop');
        });

        $(".n2").click(function( event ) {
            event.preventDefault();
            scrolltoform(this.id);
            $(".se1").toggleClass('blind');
            $(".se2").removeClass('blind');
            $(".se3").removeClass('blind');
        });

        $(".n4").click(function( event ) {
            event.preventDefault();
            scrolltoform(this.id);
            $(".se2").toggleClass('blind');
            $(".se1").removeClass('blind');
            $(".se3").removeClass('blind');
        });

        // $(".timeline_post_abuse").click(function( event ) {
        //     event.preventDefault();
        //     $(".report_abuse").toggleClass('blind');
        //     $(".se2").removeClass('blind');
        //     $(".se1").removeClass('blind');
        // });

        function scrolltoform(id){
            $('html,body').animate({
                scrollTop: $("#sidenav_expand").offset().top-100},
            'slow');
        }

        $(".close_post").click(function()   {
			console.log('pow');
            $("#sidenav_expand section").removeClass('blind');
        });

		$(".close_post_abuse").click(function()   {
			console.log('pow');
            $(".report_abuse").removeClass('blind');
        });

		$(".edit_close_post").click(function()   {
			console.log('pow');
            $(".edit_post_timeline").removeClass('blind');
        });
    }

};


var pingalaAjax = {

	makeRequest: function(URL, Data, getID){
		$(getID).fadeOut('fast', function(){
			$.ajax({
				url: URL,
				type: 'POST',
				data:  'ajax=1&'+Data.split("?")[1],
				success: function(data) {
				$(getID).html(data);
			//	eval($(getID).innerHTML);
				$(getID).fadeIn();
			  }
			});
		})

	},


	submitForm : function(pageUrl) {

		$('.textform').each(function() { if($(this).val()==$(this).attr('placeholder')) {$(this).val('');} });

		$('#submitButton').type == 'button';
		$('#submitButton').disabled = 'disabled';

		if(pageUrl) {pageLocation = pageUrl} else {pageLocation = window.location}

		waitPadd = (($('#cmsForm')).height() - 48) /2;
		$('#waiting').css('paddingTop', waitPadd + 'px');
		$('#waiting').css('paddingBottom', waitPadd + 'px');
		$('#waiting').css('width', $('#cmsForm').width() + 'px');

		$('#cmsForm').fadeTo('fast', 0,function(){

			$('#waiting').fadeIn('slow', function(){
				$.post(pageUrl, 'ajax=1&' +  $('#cmsForm').serialize(), function(data){pingalaAjax.showResponse(data)});
			})

		 });

	},

	showResponse : function(originalRequest) {

			if(originalRequest.substring(0,4) == 'http') {
				window.location = originalRequest;
			  } else {
				$('#cmsForm').html(originalRequest);
				$('#waiting').fadeOut() ;
				$('#cmsForm').fadeTo('slow',1, function(){
					$('#submitButton').effect('shake', { times:3 }, 800, function(){ $('body').scrollTo( '0', 700 ); } );
				});
				pingalaCMS.formStyle();

  			}

	}

};

pingalaWeb.expandpost();
pingalaWeb.showform();
pingalaWeb.lazyLoad();
pingalaWeb.post_toggle();

$(document).ready(pingalaCMS.load);
