/////// Mikes JS ///////
$(function () {
    Dropzone.autoDiscover = false;

    $('#new-post-dropzone').dropzone({
        autoProcessQueue: false,
        uploadMultiple: true,
        parallelUploads: 100,
        maxFiles: 100,

        clickable: '#new-post-add-image-button',
        dictDefaultMessage: 'Add',
        dictResponseError: 'Error',
        addRemoveLinks: true,
        previewTemplate: '<div class="dz-preview dz-file-preview" style="display: inline-block;"><div class="dz-details"><div class="dz-filename"><span data-dz-name></span></div> <div class="dz-size" data-dz-size></div><img data-dz-thumbnail /></div><div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div> </div>',

        // The setting up of the dropzone
        init: function() {
            var myDropzone = this;

            // First change the button to actually tell Dropzone to process the queue.
            this.element.querySelector("#post_submit").addEventListener("click", function (e) {
                if (myDropzone.getQueuedFiles().length) {
                    // Make sure that the form isn't actually being sent.
                    e.preventDefault();
                    //e.stopPropagation();
                    myDropzone.processQueue();
                }
            });
            this.on('completemultiple', function () {
                location.reload();
            });
        }
    });

    $('.edit-post-dropzone').dropzone({
        autoProcessQueue: false,
        uploadMultiple: true,
        parallelUploads: 100,
        maxFiles: 100,

        //clickable: '.edit-post-add-image-button',
        dictDefaultMessage: 'Add',
        dictResponseError: 'Error',
        addRemoveLinks: true,
        previewTemplate: '<div class="dz-preview-edit dz-file-preview-edit" style="float:left;"> '+
                            '<div class="dz-details-edit">'+
                                '<img data-dz-thumbnail />' +
                            '</div>' +
                        '</div>',

        // The setting up of the dropzone
        init: function() {
            var myDropzone = this;

            // First change the button to actually tell Dropzone to process the queue.
            this.element.querySelector(".edit_post_submit").addEventListener("click", function (e) {
                if (myDropzone.getQueuedFiles().length) {
                    // Make sure that the form isn't actually being sent.
                    e.preventDefault();
                    //e.stopPropagation();
                    myDropzone.processQueue();
                }
            });
            this.on('completemultiple', function () {
                location.reload();
            });
        }
    });

    $('#edit-user-dropzone').dropzone({
        autoProcessQueue: false,
        uploadMultiple: false,
        parallelUploads: 100,
        maxFiles: 100,

        clickable: '.timeline-user-add-image-button',
        dictDefaultMessage: 'Add',
        dictResponseError: 'Error',
        addRemoveLinks: true,
        previewTemplate: '<div class="dz-preview dz-file-preview" style="display: inline-block;"><div class="dz-details"><img data-dz-thumbnail /></div><div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div> </div>',

        // The setting up of the dropzone
        init: function() {
            var myDropzone = this;

            // First change the button to actually tell Dropzone to process the queue.
            this.element.querySelector("#edit_timeline_user").addEventListener("click", function (e) {
                if (myDropzone.getQueuedFiles().length) {
                    // Make sure that the form isn't actually being sent.
                    e.preventDefault();
                    //e.stopPropagation();
                    myDropzone.processQueue();
                }
            });
            this.on('completemultiple', function () {
                location.reload();
            });
            this.on("success", function () {
                location.reload();
            });
        }
    });

    $('#add-user-dropzone').dropzone({
        autoProcessQueue: false,
        uploadMultiple: false,
        parallelUploads: 100,
        maxFiles: 100,

        clickable: '.timeline-user-add-image-button',
        dictDefaultMessage: 'Add',
        dictResponseError: 'Error',
        addRemoveLinks: true,
        previewTemplate: '<div class="dz-preview dz-file-preview" style="display: inline-block;"><div class="dz-details"><div class="dz-filename"><span data-dz-name></span></div> <div class="dz-size" data-dz-size></div><img data-dz-thumbnail /></div><div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div> </div>',

        // The setting up of the dropzone
        init: function() {
            var myDropzone = this;

            // First change the button to actually tell Dropzone to process the queue.
            this.element.querySelector("#add_timeline_user").addEventListener("click", function (e) {
                if (myDropzone.getQueuedFiles().length) {
                    // Make sure that the form isn't actually being sent.
                    e.preventDefault();
                    //e.stopPropagation();
                    myDropzone.processQueue();
                }
            });
            this.on('completemultiple', function () {
                location.reload();
            });
            this.on("success", function () {
                location.reload();
            });
        }
    });

    $('#edit-time-dropzone').dropzone({
        autoProcessQueue: false,
        uploadMultiple: false,
        parallelUploads: 100,
        maxFiles: 100,

        clickable: '.edit-timeline-add-image-button',
        dictDefaultMessage: 'Add',
        dictResponseError: 'Error',
        addRemoveLinks: true,
        previewTemplate: '<div class="dz-preview dz-file-preview" style="display: inline-block;"><div class="dz-details"><div class="dz-filename"><span data-dz-name></span></div> <div class="dz-size" data-dz-size></div><img data-dz-thumbnail /></div><div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div> </div>',

        // The setting up of the dropzone
        init: function() {
            var myDropzone = this;

            // First change the button to actually tell Dropzone to process the queue.
            this.element.querySelector("#edit_timeline").addEventListener("click", function (e) {
                if (myDropzone.getQueuedFiles().length) {
                    // Make sure that the form isn't actually being sent.
                    e.preventDefault();
                    //e.stopPropagation();
                    myDropzone.processQueue();
                }
            });
            this.on('completemultiple', function () {
                location.reload();
            });
            this.on("success", function () {
                location.reload();
            });
        }
    });

    $('#add-time-dropzone').dropzone({
        autoProcessQueue: false,
        uploadMultiple: false,
        parallelUploads: 100,
        maxFiles: 100,

        clickable: '.add-timeline-add-image-button',
        dictDefaultMessage: 'Add',
        dictResponseError: 'Error',
        addRemoveLinks: true,
        previewTemplate: '<div class="dz-preview dz-file-preview" style="display: inline-block;"><div class="dz-details"><div class="dz-filename"><span data-dz-name></span></div> <div class="dz-size" data-dz-size></div><img data-dz-thumbnail /></div><div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div> </div>',

        // The setting up of the dropzone
        init: function() {
            var myDropzone = this;

            // First change the button to actually tell Dropzone to process the queue.
            this.element.querySelector("#add_timeline").addEventListener("click", function (e) {
                if (myDropzone.getQueuedFiles().length) {
                    // Make sure that the form isn't actually being sent.
                    e.preventDefault();
                    //e.stopPropagation();
                    myDropzone.processQueue();
                }
            });
            this.on('success', function (e) {
                window.history.back();
            });
        }
    });

    $(".timeline_post_abuse").click(function( event ) {
        event.preventDefault();
        var timeline_id = $(this).data('post-id');
        console.log(timeline_id);
        $("#edit_post_timeline_" + timeline_id).removeClass('blind');
        $("#report_abuse_" + timeline_id).toggleClass('blind');
    });

    $(".timeline_post_edit").click(function( event ) {
        event.preventDefault();
        var timeline_post_id = $(this).data('edit-post-id');

        console.log(timeline_post_id);
        $("#edit_post_timeline_" + timeline_post_id).toggleClass('blind');
        $("#report_abuse_" + timeline_post_id).removeClass('blind');
    });

    $('.datetimepicker').datetimepicker({
        useCurrent: false,
        format: 'D/M/YYYY',
        inline: true,
        sideBySide: true
    });

    $('.datetimepicker1').datetimepicker({
        useCurrent: false,
        format: 'D/M/YYYY',
        inline: true,
        sideBySide: true
    });

    $('#datetimepicker12').datetimepicker({
        inline: true,
        sideBySide: true
    });

    ////// AJAX POSTS ON TIMELINE //////////////
    $("#invite_relative_button").click(function(){
        var timeline_id = $('#invite_timeline_id').val();
        var fullname = $('#invite_fullname').val();
        var email = $('#invite_email').val();

        $.ajax({
            url: '/timelines/invite-to-timeline',
            type: 'post',
            data: {
                'timeline_id': timeline_id,
                'fullname': fullname,
                'email' : email
            },
            success: function(data) {
                console.log(data);
                location.reload();
            }
        }); // end ajax call
    });

    $("#admin_message_button").click(function(){
        var timeline_id = $('#admin_message_timeline_id').val();
        var title = $('#admin_message_title').val();
        var content = $('#admin_message_content').val();

        $.ajax({
            url: '/timelines/admin-message',
            type: 'post',
            data: {
                'admin_message_timeline_id': timeline_id,
                'admin_message_title': title,
                'admin_message_content' : content
            },
            success: function(data) {
                console.log(data);
                location.reload();
            }
        }); // end ajax call
    });

    $(".report_abuse_button").click(function(){
        var timeline_post_id = $(this).data('post-id');
        var timeline_id = $(this).data('timeline-id');
        var timeline_post_message = $('#message_' + timeline_post_id).val();

        $.ajax({
            url: '/timelines/report-abuse',
            type: 'post',
            data: {
                'timeline_post_id': timeline_post_id,
                'timeline_post_message': timeline_post_message,
                'timeline_id': timeline_id
            },
            success: function(data) {
                console.log(data);
                location.reload();
            }
        }); // end ajax call
    });

    $(".timeline_post_delete").click(function() {
        $("#delete-timeline").fadeIn(400);
        var timeline_post_id = $(this).data('delete-post-id');
        $(".confirm-delete-user-post").val(timeline_post_id);
    });

    $(".confirm-delete-user-post").click(function(){
        var timeline_post_id = $(this).val();

    	$.ajax({
    		url: '/timelines/'+ timeline_post_id + '/destroy-timeline-post',
    		type: 'delete',
    		data: {
    			'timeline_id': timeline_post_id
    		},
    		success: function(data) {
    			console.log(data);
    			location.reload();
    		}
    	}); // end ajax call
    });

    $(".admin-delete-post").click(function() {
        $("#delete-timeline").fadeIn(400);
        var timeline_id = $(this).data('delete-post-id');
        $(".confirm-delete-post").val(timeline_id);
    });

    $(".confirm-delete-post").click(function(){
        var timeline_post_id = $(this).val();
        console.log(timeline_post_id);

    	$.ajax({
    		url: '/timelines/'+ timeline_post_id + '/destroy-timeline-post',
    		type: 'delete',
    		data: {
    			'timeline_id': timeline_post_id
    		},
    		success: function(data) {
    			console.log(data);
    			location.reload();
    		}
    	}); // end ajax call
    });

    $(".admin-delete-timeline").click(function() {
        $("#delete-timeline").fadeIn(400);
        var timeline_id = $(this).data('delete-timeline-id');
        $("#confirm-delete-timeline").val(timeline_id);
    });

    $(".cancel-delete").click(function() {
        $("#delete-timeline").hide();
    });

    $("#confirm-delete-timeline").click(function(){
        var timeline_id = $(this).val();

    	$.ajax({
    		url: '/admin/'+ timeline_id + '/admin-delete-timeline',
    		type: 'delete',
    		data: {
    			'timeline_id': timeline_id
    		},
    		success: function(data) {
    			console.log(data);
    			location.reload();
    		}
    	}); // end ajax call
    });

    $(".remove-from-timeline").click(function() {
        $("#delete-timeline").fadeIn(400);
        var timeline_id = $(this).data('remove-timeline-id');
        $(".confirm-delete-user-from-timeline").val(timeline_id);
    });

    $(".confirm-delete-user-from-timeline").click(function(){
        var timeline_id = $(this).val();

    	$.ajax({
    		url: '/timelines/'+ timeline_id + '/remove-from-timeline' ,
    		type: 'delete',
    		data: {
    			'timeline_id': timeline_id
    		},
    		success: function(data) {
    			console.log(data);
    			location.reload();
    		}
    	}); // end ajax call
    });

    $(".timeline_post_delete_image").click(function(){
        var timeline_media_id = $(this).data('delete-post-media-id');

    	$.ajax({
    		url: '/timelines/'+ timeline_media_id +'/delete-timeline-image',
    		type: 'delete',
    		data: {
    			'timeline_media_id': timeline_media_id
    		},
    		success: function(data) {
    			console.log(data);
    			location.reload();
    		}
    	}); // end ajax call
    });

    $(".edit_timeline_user").click(function(){
        var user_id = $(this).data('');

        $.ajax({
            url: '/timelines/'+ user_id +'/edit-timeline-user',
            type: 'delete',
            data: {
                'user_id': user_id
            },
            success: function(data) {
                console.log(data);
                location.reload();
            }
        }); // end ajax call
    });

    $(".admin-delete-user").click(function() {
        $("#delete-timeline").fadeIn(400);
        var user_id = $(this).data('user-id');
        $(".confirm-delete-user").val(user_id);
    });

    $(".cancel-delete").click(function() {
        $("#delete-user").hide();
    });

    $(".confirm-delete-user").click(function(){
        var user_id = $('.confirm-delete-user').val();

        console.log(user_id);

        $.ajax({
            url: '/admin/'+ user_id +'/admin-delete-user',
            type: 'delete',
            data: {
                'user_id': user_id
            },
            success: function(data) {
                console.log(data);
                location.reload();
            }
        }); // end ajax call
    });

///////////////////////////////////////////////

    $("#close_post").click(function()   {
        $("#post_to_timeline").toggleClass('blind');
    });

    $(".logout i").click(function() {
        $("#logout-confirm").toggleClass('out');
    });

    $(".no").click(function() {
        $("#logout-confirm").toggleClass('out');
    });

    $(".parallax-window").parallax({imageSrc: '/assets/images/banner.jpg'});


    $("#show_edit").click(function() {
        $(".user_edit").toggleClass('blind');
        });


    $(".yes").click(function(){
        $.ajax({
            url: '/logout',
            type: 'get',
            success: function() {
            }
        });
    });

    $(".message_view").click(function() {
        var id = $(this).data('id');

        $.ajax({
            url: '/admin/'+ id +'/get-abuse-message',
            type: 'post',
            data: {
                'user_id': id
            },
            success: function(data) {
                console.log(data);
                var note = data[0].note;
                var content = data[1].content;
                $('#user-message').html(note);
                $('#abuse-message').html(content);
                //location.reload();
            }
        }); // end ajax call
        $("#message_up").fadeIn(400);
    });

    $(".go-to-abusive-post").click(function() {
        var post_id = $(this).data('timeline-post-id');
        var timeline_url = $('#timeline-url').val();
        window.location = "/timelines/" + timeline_url + "/#timeline_post_" + post_id;
    });

    $("#message_up #close").click(function() {
        $("#message_up").fadeOut(400);
    });

    $("#date_range_selector").change(function() {
        var year_range = $(this).val();
        var time_line_id = $('#timeline_id').val();

        if ( year_range === 'clear') {
            window.location = "/timelines/" + time_line_id;
        } else {
            window.location = "/timelines/" + time_line_id + "?date_range=" + year_range;
        }
    });
});
